import { connect } from "react-redux";
import React from "react";

export function withReduxConnect(mapStateToProps, mapDispatchToProps) {
    // Erst connect erstellen
    const connectHOC = connect(mapStateToProps, mapDispatchToProps, null, {
        // pure: true,
        forwardRef: true,
    });

    // Dann den Wrapper zurückgeben
    return function wrapWithConnect(WrappedComponent) {
        if (!WrappedComponent) {
            throw new Error("You must pass a component to withReduxConnect");
        }

        // Erstelle eine echte React-Komponente
        class WithReduxConnect extends React.Component {
            render() {
                return <WrappedComponent {...this.props} />;
            }
        }

        // Setze den Display-Namen für besseres Debugging
        const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
        WithReduxConnect.displayName = `withReduxConnect(${displayName})`;

        // Verbinde mit Redux
        return connectHOC(WithReduxConnect);
    };
}
