import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";
import { clearCachePool } from "./settingActions";

const config = new Config();

export const RECEIVE_DUMMIES = "RECEIVE_DUMMIES";
export function receiveDummies(json) {
    return {
        type: RECEIVE_DUMMIES,
        dummies: json,
    };
}

export const RECEIVE_RECIPE = "RECEIVE_RECIPE";
export function receiveRecipe(json) {
    return {
        type: RECEIVE_RECIPE,
        selected: json,
    };
}

export const RECEIVE_RECIPES = "RECEIVE_RECIPES";
export function receiveRecipes(json) {
    return {
        type: RECEIVE_RECIPES,
        all: json,
    };
}

/*Hier wird ein Rezept ausgedruckt*/
export function createRecipeReport(recipesList, reportType, userLoggedIn) {
    return function () {
        return fetch(config.backendHost + "/Reports/CreateRecipeReport", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify({ recipesList, reportType, userLoggedIn }),
        }).then((response) => handleResponse(response));
    };
}

export function createCookingProcessRecipe(cookingProcessId, recursive) {
    return function () {
        return fetch(config.backendHost + "/Reports/CreateCookingProcessRecipe", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify({ cookingProcessId, recursive }),
        }).then((response) => handleResponse(response));
    };
}

export function createCookingIngredientsRecipe(cookingProcessId) {
    return function () {
        return fetch(config.backendHost + "/Reports/CreateCookingIngredientsRecipe", {
            method: "post",
            headers: authHeader(),
            body: cookingProcessId,
        }).then((response) => handleResponse(response));
    };
}

export function createIngredientsRecipeForAllRunningCookingProcesses(cookingProcessId) {
    return function () {
        return fetch(config.backendHost + "/Reports/CreateIngredientsRecipeForAllRunningCookingProcesses", {
            method: "GET",
            headers: authHeader(),
        }).then((response) => handleResponse(response));
    };
}

/*Hier werden Steps geupdated*/
export function updateStep(step) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdateStep", {
            method: "post",
            headers: authHeader(),
            body: step,
        }).then((response) => handleResponse(response));
        //.then(response => dispatch(receiveSteps(response)));
    };
}

/*Hier werden Rezepte gelöscht*/
export function deleteRecipe(recipeId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/Delete", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify(recipeId.id),
        }).then((response) => handleResponse(response));
    };
}

export function toggleWebshopEnabled(recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/toggleWebshopEnabled", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify(recipesId),
        }).then((response) => handleResponse(response));
    };
}

/*Hier werden Rezepte geupdated*/
export function updateRecipe(recipe) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdateRecipe", {
            method: "post",
            headers: authHeader(),
            body: recipe,
        }).then((response) => {
            dispatch(clearCachePool("Articles"));
            return handleResponse(response);
        });
    };
}

/*Hier werden Rezepte hinzugefügt*/
export function addRecipe(recipe) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/AddRecipe", {
            method: "post",
            headers: authHeader(),
            body: recipe,
        }).then((response) => handleResponse(response));
    };
}

export function removeStepIngredient(stepIngredientId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/RemoveStepIngredient", {
            method: "POST",
            headers: authHeader(),
            body: stepIngredientId,
        }).then((response) => handleResponse(response));
        //.then(() => dispatch(fetchRecipe(data.recipesId)));
    };
}

export function addStepIngredient(ingredientId, stepId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/AddStepIngredient", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ingredientId, stepId }),
        }).then((response) => handleResponse(response));
        //.then(() => dispatch(fetchRecipe(data.recipesId)));
    };
}

export function fetchArticlesWithoutRecipe() {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetAllArticlesWithoutRecipe", {
            method: "get",
            headers: authHeader(),
        })
            .then((response) => handleResponse(response))
            .then((response) => dispatch(receiveArticlesWithoutRecipe(response)));
    };
}

export const RECEIVE_ARTICLESWORECIPE = "RECEIVE_ARTICLESWORECIPE";
export function receiveArticlesWithoutRecipe(json) {
    return {
        type: RECEIVE_ARTICLESWORECIPE,
        articlesworecipe: json,
    };
}

export const RECEIVE_RECIPETOOLS = "RECEIVE_RECIPETOOLS";
export function receiveRecipeTools(json) {
    return {
        type: RECEIVE_RECIPETOOLS,
        tools: json,
    };
}

export const RECEIVE_RECIPEWORKINGSTATIONS = "RECEIVE_RECIPEWORKINGSTATIONS";
export function receiveWorkingStations(json) {
    return {
        type: RECEIVE_RECIPEWORKINGSTATIONS,
        workingStations: json,
    };
}

export const RECEIVE_ARTICLEDIETS = "RECEIVE_ARTICLEDIETS";
export function receiveArticleDiets(json) {
    return {
        type: RECEIVE_ARTICLEDIETS,
        diets: json,
    };
}

export const RECEIVE_PHASETYPES = "RECEIVE_PHASETYPES";
export function receivePhaseTypes(json) {
    return {
        type: RECEIVE_PHASETYPES,
        phaseTypes: json,
    };
}
/*Hier werden alle Rezepte geladen geladen*/
export function fetchRecipes() {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetAllRecipes", {
            method: "get",
            headers: authHeader(),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveRecipes(json)));
    };
}

export function getAllRecipesWithArticleGroupName() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllRecipesWithArticleGroupName`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveRecipe(json)));
    };
}

export function fetchRecipe(recipeId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ recipeId: recipeId }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getRecipe`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveRecipe(json)));
    };
}

export function updateStepTools(stepsId, stepToolIds) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ stepsId: stepsId, stepToolIds: stepToolIds }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/updateStepTools", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchRecipeTools() {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetAllRecipeTools", {
            method: "get",
            headers: authHeader(),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveRecipeTools(json)));
    };
}
export function fetchRecipeWorkingStations() {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetAllRecipeWorkingStations", {
            method: "get",
            headers: authHeader(),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveWorkingStations(json)));
    };
}

export function fetchArticleDiets() {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetAllArticleDiets", {
            method: "get",
            headers: authHeader(),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleDiets(json)));
    };
}

export function fetchPhaseTypes() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllPhaseTypes`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertPhase(data) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/InsertPhase", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(data),
        })
            .then((response) => handleResponse(response))
            .then(() => dispatch(fetchArticlesWithRecipesId(data.recipesId)));
    };
}

export function fetchPhasesFromRecipe(recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetPhasesFromRecipe", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(recipesId),
        }).then((response) => handleResponse(response));
    };
}

export function fetchPhaseByPhasesId(phasesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetPhaseByPhasesId", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(phasesId),
        }).then((response) => handleResponse(response));
    };
}

export function fetchStepByStepsId(stepsId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetStepByStepsId", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(stepsId),
        }).then((response) => handleResponse(response));
    };
}

export function insertPhaseInRecipe(phasesId, recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/InsertPhaseInRecipe", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ phasesId, recipesId }),
        })
            .then((response) => handleResponse(response))
            .then(() => dispatch(fetchArticlesWithRecipesId(recipesId)));
    };
}

export function updatePhase(phase, recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdatePhase", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(phase),
        }).then((response) => handleResponse(response));
    };
}

export function reorderStep(phasesId, stepsId, oldIndex, newIndex) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/reorderStep", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ phasesId, stepsId, oldIndex, newIndex }),
        }).then((response) => handleResponse(response));
    };
}

export function deleteStep(stepsId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/deleteStep", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(stepsId),
        }).then((response) => handleResponse(response));
    };
}

export function insertStepAtIndex(phasesId, newIndex, step) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/insertStepAtIndex", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ phasesId, newIndex, step }),
        }).then((response) => handleResponse(response));
    };
}

export function addStep(user, phasesId, recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/AddStep", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ user, phasesId }),
        }).then((response) => handleResponse(response));
    };
}

export function updatePhaseType(phaseTypesId, phasesId, recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdatePhaseType", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ phaseTypesId, phasesId }),
        }).then((response) => handleResponse(response));
    };
}

export function updatePhasePreparationLocation(preparationLocationsId, phasesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdatePhasePreparationLocation", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ preparationLocationsId, phasesId }),
        }).then((response) => handleResponse(response));
    };
}

export function deletePhase(userLoggedIn, phasesIdToDelete, recipesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/DeletePhase", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ userLoggedIn, phasesIdToDelete }),
        })
            .then((response) => handleResponse(response))
            .then((response) => dispatch(fetchArticlesWithRecipesId(recipesId)));
    };
}

export const ARTICLESWITHRECIPES = "RECEIVE_ARTICLESWITHRECIPES";
export function receiveArticlesWithRecipes(json) {
    return {
        type: RECEIVE_ARTICLESWORECIPE,
        articlesworecipe: json,
    };
}

export function fetchArticlesWithRecipesId(recipeId) {
    const requestHelpers = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ recipeId: Number(recipeId) }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getRecipeWithRecipesId`, requestHelpers)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveRecipe(json)));
    };
}

export function updateStepPicture(step, guidFileName = null) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ stepsId: step.stepsId, phasesId: step.phasesId, guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/UpdateStepPicture", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchRecipeWithPhasesId(phasesId) {
    const requestHelpers = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Number(phasesId)),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetRecipeWithPhasesId", requestHelpers)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function fetchStepsWithImage(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Recipes/GetStepsWithImage", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}
