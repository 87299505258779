import Config from "../../Config";
import { authHeader, authHeaderForAzure, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const RECEIVE_ALLCATEGORIES = "RECEIVE_ALLCATEGORIES";
export function receiveAllCategories(json) {
    return {
        type: RECEIVE_ALLCATEGORIES,
        categories: json,
    };
}

export function fetchCategories(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getCategories`, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t))
            .then((json) => dispatch(receiveAllCategories(json)));
    };
}

export function updateCategories(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Categories/update", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertCategories(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Categories/insert", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteCategories(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Categories/delete", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
