import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchAllCustomers() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getAllCustomers`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                const modifiedJson = json.map((customer) => ({
                    ...customer,
                    customersIdDisplay: customer.customersId,
                }));

                return modifiedJson;
            });
    };
}

export function GetCustomersWithOrders() {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Customers/GetCustomersWithOrders", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function deleteCustomerById(customersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(customersId),
        };
        return fetch(config.backendHost + "/Customers/DeleteCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getCustomerByCustomerUid(customersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(customersId),
        };
        return fetch(config.backendHost + "/Customers/getCustomerByCustomerUid", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllDaysToCookCustomerUid(customersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(customersId),
        };
        return fetch(config.backendHost + "/Customers/getAllDaysToCookCustomerUid", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateCustomerLanguage(customerUid, languageCode) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ customerUid: customerUid, languageCode: languageCode }),
        };
        return fetch(config.backendHost + "/Customers/updateCustomerLanguage", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function GetAddressesOfCustomer(customerUid) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ customerUid: customerUid }),
        };
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getCustomerAddresses`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateStandardBillingAddress(customerUid, addressIndex) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
    };
    return function (dispatch) {
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/updateCustomerStandardBillingAddress`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function updateStandardDeliveryAddress(customerUid, addressIndex) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
    };
    return function (dispatch) {
        return fetch(
            `${config.apiUrl}/${config.cashregisterModule}/updateCustomerStandardDeliveryAddress`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function deleteAddress(addressUid) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({
            addressUid: addressUid,
        }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/deleteAddress`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateAddress(
    customerUid,
    title,
    firstName,
    lastName,
    addressIndex,
    city,
    street,
    houseNumber,
    zipCode,
    company,
    addressDetails,
    isDefaultBillingAddress,
    isDefaultDeliveryAddress,
    addressUid
) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({
            customerUid: customerUid,
            title: title,
            firstName: firstName,
            lastName: lastName,
            addressIndex: addressIndex,
            city: city,
            street: street,
            houseNumber: houseNumber,
            zipCode: zipCode,
            company: company,
            addressDetails: addressDetails,
            isDefaultDeliveryAddress: isDefaultDeliveryAddress,
            isDefaultBillingAddress: isDefaultBillingAddress,
            addressUid: addressUid,
        }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/updateAddress`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
