import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRouteAdmin = ({ children }) => {
    const location = useLocation();
    let u = JSON.parse(localStorage.getItem("user"));

    if (!u || !u.Id || !u.Username || !u.Token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};
