import { createHashHistory } from "history";

// Erstelle history-Objekt
const history = createHashHistory();

// Füge Kompatibilitätsschicht hinzu
const compatHistory = {
    ...history,
    push: (path, state) => {
        if (typeof path === "string") {
            history.push(path, state);
        } else {
            // Unterstützung für Objekt-Syntax: history.push({ pathname: '/path', state: {} })
            history.push(path.pathname, path.state);
        }
    },
    replace: (path, state) => {
        if (typeof path === "string") {
            history.replace(path, state);
        } else {
            history.replace(path.pathname, path.state);
        }
    },
    listen: (listener) => {
        return history.listen(({ location, action }) => {
            listener(location, action);
        });
    },
};

export { compatHistory as history };
