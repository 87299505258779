import React, { Component } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withReduxConnect } from "../../../../helpers/withReduxConnect";

class Breadcrumbs extends Component {
    render() {
        const { currentPage } = this.props.pages;
        const { t } = this.props;
        return (
            <span
                style={{
                    backgroundColor: "transparent",
                    color: "white",
                    marginLeft: 55,
                    fontSize: "small",
                }}
            >
                {currentPage.breadcrumbs && currentPage.breadcrumbs.length > 0 && this.props.identity.user.Username && (
                    <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Link to="/">
                                <span>
                                    <HomeIcon fontSize="small" />
                                </span>
                            </Link>

                            {currentPage.breadcrumbs.map((crumb, index) => {
                                if (crumb)
                                    return (
                                        <Link
                                            key={index}
                                            to={crumb.route ? crumb.route : "#"}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <span
                                                style={{ marginLeft: 3, marginRight: 3 }}
                                                onClick={() => {
                                                    if (index === currentPage.breadcrumbs.length - 1) {
                                                        window.location.reload();
                                                    }
                                                }}
                                            >
                                                <span style={{ marginRight: 7 }}>{"/"}</span>
                                                {t(crumb.text)}
                                            </span>
                                        </Link>
                                    );
                                else return <React.Fragment />;
                            })}
                        </span>
                    </span>
                )}
            </span>
        );
    }
}

function mapStateToProps(state) {
    const { navigation, pages, identity } = state;
    return {
        navigation,
        pages,
        identity,
    };
}

export default compose(withReduxConnect(mapStateToProps), withTranslation(["dynamicTranslation"]))(Breadcrumbs);
