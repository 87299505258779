import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchCookingProcesses() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getAllCookingProcesses", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchAllCookingProcessesByCustomerUIDAndDay(customersUid, day) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid, day }),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/CookingProcess/getAllCookingProcessesByCustomerUIDAndDay",
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function fetchCookingProcessesCompleted() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getAllCookingProcessesCompleted", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function fetchCookingProcessById(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: cookingProcessId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getCookingProcessById", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCookingProcessPhaseById(phasesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: phasesId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getPhaseById", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCookingProcessStepById(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: stepsId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getStepById", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCookingProcessCustomerById(customersUid, cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid, cookingProcessId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getCookingProcessCustomerById", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function fetchCookingProcessCustomerPhaseById(customersUid, phasesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid, phasesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getCustomerPhaseById", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCookingProcessCustomerStepById(customersUid, stepsId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid, stepsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getCustomerStepById", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchArticles() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/getArticlesForCookingProcess", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteCookingProcess(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: cookingProcessId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/deleteCookingProcess", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function finishCookingProcess(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: cookingProcessId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/finishCookingProcess", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function finishCookingProcessCustomer(cookingProcessId, customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/finishCookingProcessCustomer", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateCookingProcess(cookingProcessId, forceUpdate = false) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, forceUpdate }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/updateCookingProcess", requestOptions).then((response) => {
            dispatch(fetchAllCookingProcessesWithStartedTimer());
            return handleResponse(response);
        });
    };
}

export function updateCookingProcessCustomer(cookingProcessId, customersUid, forceUpdate = false) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, forceUpdate, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/updateCookingProcessCustomer", requestOptions).then(
            (response) => {
                return handleResponse(response);
            }
        );
    };
}

export function fetchPhaseIngredients(cookingProcessId, phasesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, phasesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getPhaseIngredients", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCustomerPhaseIngredients(customersUid, cookingProcessId, phasesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid, cookingProcessId, phasesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getCustomerPhaseIngredients", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function createCookingProcessWithArticleSizes(articlesId, cookingProcessArticleSizes, existingArticleWeights) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, cookingProcessArticleSizes, existingArticleWeights }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/createCookingProcessWithArticleSizes", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function createCookingProcessWithArticleWeights(
    articlesId,
    cookingProcessArticleWeights,
    existingArticleWeights
) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, cookingProcessArticleWeights, existingArticleWeights }),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/CookingProcess/createCookingProcessWithArticleWeights",
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function setOrderDetailUsedForCookingProcess(orderDetailsIds) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ orderDetailsIds }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/setOrderDetailUsedForCookingProcess", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateCookingProcessArticleSizes(cookingProcessId, cookingProcessArticleSizes, existingArticleWeights) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, cookingProcessArticleSizes, existingArticleWeights }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/updateCookingProcessArticleSizes", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateCookingProcessArticleWeights(
    cookingProcessId,
    cookingProcessArticleWeights,
    existingArticleWeights
) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, cookingProcessArticleWeights, existingArticleWeights }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/updateCookingProcessArticleWeights", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function cookingProcessUpdateStep(cookingProcessId, nextStepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, nextStepsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/cookingProcessUpdateStep", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function cookingProcessUpdatePhase(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/cookingProcessUpdatePhase", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function restartCookingProcess(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: cookingProcessId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/restartCookingProcess", requestOptions).then((response) => {
            dispatch(fetchAllCookingProcessesWithStartedTimer());
            return handleResponse(response);
        });
    };
}

export function restartCookingProcessCustomer(cookingProcessId, customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/restartCustomerCookingProcess", requestOptions).then(
            (response) => {
                return handleResponse(response);
            }
        );
    };
}

export function restartCurrentPhase(cookingProcessId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: cookingProcessId,
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/restartCurrentPhase", requestOptions).then((response) => {
            dispatch(fetchAllCookingProcessesWithStartedTimer());
            return handleResponse(response);
        });
    };
}

export function restartCurrentPhaseCustomer(cookingProcessId, customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/restartCurrentPhaseCustomer", requestOptions).then(
            (response) => {
                return handleResponse(response);
            }
        );
    };
}

export function cookingProcessUpdateTimer(cookingProcessId, currentTimerStart, logSkipTimer = false) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, currentTimerStart, logSkipTimer }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/cookingProcessUpdateTimer", requestOptions).then(
            (response) => {
                dispatch(fetchAllCookingProcessesWithStartedTimer());

                return handleResponse(response);
            }
        );
    };
}

export function cookingProcessCustomerUpdateTimer(cookingProcessId, currentTimerStart, customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, currentTimerStart, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/cookingProcessCustomerUpdateTimer", requestOptions).then(
            (response) => {
                return handleResponse(response);
            }
        );
    };
}

export const GET_COOKING_PROCESSES_WITH_TIMER_STARTED = "GET_COOKING_PROCESSES_WITH_TIMER_STARTED";
export function getCookingProcessesWithTimerStarted() {
    return {
        type: GET_COOKING_PROCESSES_WITH_TIMER_STARTED,
    };
}

export const COOKING_PROCESSES_WITH_TIMER_STARTED = "COOKING_PROCESSES_WITH_TIMER_STARTED";
export function cookingProcessesWithTimerStarted(cookingProcesses) {
    return {
        type: COOKING_PROCESSES_WITH_TIMER_STARTED,
        cookingProcesses: cookingProcesses,
    };
}

export function fetchAllCookingProcessesWithStartedTimer() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        dispatch({ type: GET_COOKING_PROCESSES_WITH_TIMER_STARTED });
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllCookingProcessesWithStartedTimer`, requestOptions)
            .then((response) => handleResponse(response))
            .then((data) => {
                dispatch({ type: COOKING_PROCESSES_WITH_TIMER_STARTED, cookingProcesses: data });
            });
    };
}

export function fetchStepIngredients(cookingProcessId, stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ cookingProcessId, stepsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/getStepIngredients", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchCustomerStepIngredients(cookingProcessId, stepsId, customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cookingProcessId, stepsId, customersUid }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/CookingProcess/GetCustomerStepIngredients", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export const SHOW_COOKING_PROCESSES_CALENDAR = "SHOW_COOKING_PROCESSES_CALENDAR";
export function showCookingProcessesCalendar(visible) {
    return {
        type: SHOW_COOKING_PROCESSES_CALENDAR,
        cookingProcessesCalendarVisible: visible,
    };
}

export const UPDATE_CUSTOMER_LANGUAGE = "UPDATE_CUSTOMER_LANGUAGE";
export function updateCustomerLanguageRedux(language) {
    return {
        type: UPDATE_CUSTOMER_LANGUAGE,
        customerLanguage: language,
    };
}

export const SET_COOKING_PROCESSES_CALENDAR_DATE = "SET_COOKING_PROCESSES_CALENDAR_DATE";
export function setCookingProcessesCalendarDate(date) {
    return {
        type: SET_COOKING_PROCESSES_CALENDAR_DATE,
        cookingProcessesCalendarDate: date,
    };
}
