import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import * as Sentry from "@sentry/react";
import { userConstants } from "./actions/userActions";
import { LOGOUT_USER, SET_REGISTRATION_STATUS, SET_USER, SET_USER_FAVORITES } from "./actions/identityActions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        //authenticationActions
        if (action.type === userConstants.LOGIN_REQUEST) {
            return null;
        }
        if (action.type === userConstants.LOGIN_SUCCESS) {
            return null;
        }
        if (action.type === userConstants.LOGIN_FAILURE) {
            return null;
        }
        if (action.type === userConstants.LOGOUT) {
            return null;
        }

        //identityActions
        if (action.type === SET_REGISTRATION_STATUS) {
            return null;
        }
        if (action.type === SET_USER) {
            return null;
        }
        if (action.type === LOGOUT_USER) {
            return null;
        }
        if (action.type === SET_USER_FAVORITES) {
            return null;
        }

        return action;
    },
});

const middlewares = [thunk];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer));

export default store;
