import React from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export function withRouter(WrappedComponent) {
    return function WithRouterWrapper(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [searchParams] = useSearchParams();

        // Kompatibilitätsschicht für alte history.push Aufrufe
        const history = {
            push: (path, state) => navigate(path, { state }),
            replace: (path, state) => navigate(path, { replace: true, state }),
            location,
            goBack: () => navigate(-1),
            listen: () => () => {}, // Dummy-Funktion für v5 Kompatibilität
        };

        // Simuliere das alte match Objekt
        const match = {
            params,
            path: location.pathname,
            url: location.pathname,
            isExact: true,
        };

        return (
            <WrappedComponent
                {...props}
                // Alte v5 Props direkt auf oberster Ebene
                history={history}
                location={location}
                match={match}
                // Neue v6 Props im router Objekt
                router={{
                    location,
                    navigate,
                    params,
                    searchParams,
                    history,
                }}
            />
        );
    };
}
