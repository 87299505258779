import Config from "../../Config";
import { authHeader, authHeaderForAzure, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ARTICLEGROUPSIZES = "REQUEST_ARTICLEGROUPSIZES";
export function requestArticleGroupSizes() {
    return {
        type: REQUEST_ARTICLEGROUPSIZES,
    };
}

export const RECEIVE_ARTICLEGROUPSIZES = "RECEIVE_ARTICLEGROUPSIZES";
export function receiveArticleGroupSizes(json) {
    return {
        type: RECEIVE_ARTICLEGROUPSIZES,
        articleGroupSizes: json,
    };
}

/*Diese Funktion lädt alle Größen, die anhand der Artikelgruppe (die ArticleGroupSizes) des ausgewählten Artikels zur Verfügung stehen*/
export function fetchArticleGroupSizes(id, isArticleGroup) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ id, isArticleGroup }),
    };
    return function (dispatch) {
        dispatch(requestArticleGroupSizes());
        return fetch(`${config.apiUrl}/${config.coreModule}/getArticleGroupSizes`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleGroupSizes(json)));
    };
}

/*Diese Funktion aktualisiert die ArticleGroupSizes und fügt ein neues Element hinzu, oder löscht ein vorhandenes*/
export function updateArticleGroupSizes(articleGroupsId, sizes) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articleGroupsId, sizes }),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleGroups/updateArticleGroupSizes", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function fetchSizes(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getSizes`, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}

export function updateSizes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Sizes/update", requestOptions).then((response) => handleResponse(response));
    };
}

export function insertSizes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Sizes/insert", requestOptions).then((response) => handleResponse(response));
    };
}

export function deleteSizes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Sizes/delete", requestOptions).then((response) => handleResponse(response));
    };
}
