import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";

// Wrapper-Komponente für Sentry Error Tracking mit URL-Kontext
const SentryURLWrapper = () => {
    return (
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <Outlet />
        </Sentry.ErrorBoundary>
    );
};

export default SentryURLWrapper;
