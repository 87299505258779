import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";

import "@fontsource/roboto/latin.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "devextreme/core/config";
import { licenseKey } from "./devextreme-license.ts";
import Config from "./Config";
import { LoadIndicator } from "devextreme-react";

config({
    defaultCurrency: "EUR",
    licenseKey,
});

const Configuration = new Config();

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer);

root.render(
    <Suspense
        fallback={
            <div id="themeLoading">
                <LoadIndicator width={80} height={80} />
            </div>
        }
    >
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>
);

rootContainer.classList.add(Configuration.selectedTheme);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
