import React, { useEffect, useState, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPages } from "../../../../actions/navigationAction";
import { getAssemblyVersion } from "../../../../actions/settingActions";
import { Route, Routes } from "react-router-dom";
import SentryURLWrapper from "../../../../helpers/sentryURLWrapper";
import { getPages } from "../../../core/pages/pages";
import { PrivateRoute } from "../../../core/privateRoute/privateRoute";
import { PrivateRouteAdmin } from "../../../core/privateRoute/privateRouteAdmin";
import AllCookingProcessesOverviewCustomer from "../../../pages/cookingProcess/allCookingProcessesOverviewCustomer";
import CookingProcess from "../../../pages/cookingProcess/cookingProcess";
import CookingProcessIngredientList from "../../../pages/cookingProcess/cookingProcessIngredientList";
import Login from "../../../pages/login/login";
import Register from "../../../pages/register/register";
import DynamicPage from "./dynamicPage";
import "./page.scss";

const Page = memo(() => {
    const [allNavigationTiles, setAllNavigationTiles] = useState([]);
    const dispatch = useDispatch();
    const { pages } = useSelector((state) => state.navigation);
    const { user } = useSelector((state) => state.identity);

    const initializePages = useCallback(() => {
        const pages = getPages();
        dispatch(loadPages(pages));
        dispatch(getAssemblyVersion());

        const newNavigationTiles = pages.reduce((acc, page) => {
            return page.navigationTiles ? [...acc, ...page.navigationTiles] : acc;
        }, []);

        setAllNavigationTiles(newNavigationTiles);
    }, [dispatch]);

    useEffect(() => {
        initializePages();
    }, []); // Initial load

    useEffect(() => {
        if (user.Username) {
            initializePages();
        }
    }, [user.Username, initializePages]); // Re-initialize when username changes

    return (
        <div className="pages">
            <Routes>
                <Route element={<SentryURLWrapper />}>
                    <Route path="/login" element={<Login />} />

                    <Route
                        path="/register"
                        element={
                            <PrivateRouteAdmin>
                                <Register />
                            </PrivateRouteAdmin>
                        }
                    />

                    {/* Guest Routes */}
                    <Route
                        path="allCookingProcessesCustomer/:customersUid/:type?/:parentId?"
                        element={<AllCookingProcessesOverviewCustomer />}
                    />
                    {/* Guest Routes */}
                    <Route path="cookingProcess/:id/:phasesId/:stepsId/:customersUid" element={<CookingProcess />} />
                    {/* Guest Routes */}
                    <Route
                        path="cookingProcessIngredientList/:id/:phasesId/:stepsId/:customersUid"
                        element={<CookingProcessIngredientList />}
                    />

                    {/* Dynamische Routes aus pages */}
                    {pages.map((page, index) => (
                        <Route
                            key={index}
                            path={page.route}
                            exact={page.exact}
                            element={
                                <PrivateRoute hasRight={page.hasRight}>
                                    <DynamicPage page={page} />
                                </PrivateRoute>
                            }
                        />
                    ))}

                    {/* NavigationTiles Routes */}
                    {allNavigationTiles.map((page, index) =>
                        page.component ? (
                            <Route
                                key={index}
                                path={page.route}
                                exact={page.exact}
                                element={
                                    <PrivateRoute hasRight={page.hasRight}>
                                        <DynamicPage page={page} />
                                    </PrivateRoute>
                                }
                            />
                        ) : (
                            <React.Fragment key={index} />
                        )
                    )}
                </Route>
            </Routes>
        </div>
    );
});

Page.displayName = "Page";
export default Page;
