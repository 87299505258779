import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Calendar, LoadPanel, Popup } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import notify from "devextreme/ui/notify";
import moment from "moment";
import React, { Component } from "react";
import { compose } from "redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    fetchAllCookingProcessesByCustomerUIDAndDay,
    finishCookingProcessCustomer,
    setCookingProcessesCalendarDate,
    showCookingProcessesCalendar,
} from "../../../actions/cookingProcessActions";
import { getAllDaysToCookCustomerUid, getCustomerByCustomerUid } from "../../../actions/customerActions";
import { history } from "../../../helpers/history";
import { getToast } from "../../../helpers/requestHelpers";
import { formatGrammage, getGrammageUnit } from "../../../helpers/units";
import store from "../../../store";
import AllCookingProcessesOverviewForm from "./allCookingProcessesOverviewForm";
import { withRouter } from "../../../helpers/withRouter";
import { withReduxConnect } from "../../../helpers/withReduxConnect";

class AllCookingProcessesOverviewCustomer extends Component {
    constructor(props) {
        super(props);
        loadMessages(deMessages);

        locale(navigator.language);
        this.state = {
            cookingProcesses: [],
            cookingProcessesCompleted: [],
            completedCookingProcessesVisble: false,
            customersUid: this.props.match.params.customersUid ? this.props.match.params.customersUid : null,
            parentId: this.props.match.params.parentId ? parseInt(this.props.match.params.parentId) : null,
            showChildCookingProcesses: this.props.match.params.type ? this.props.match.params.type === "p" : true,
            isLoading: false,
            showPrintPopup: false,
            calendarVisible: false,
            requestedDeliveryTimes: [],
            nextCookingProcessDate: null,
        };

        this.finishCookingProcess = this.finishCookingProcess.bind(this);
        this.toggleLoadPanel = this.toggleLoadPanel.bind(this);
        this.groupCookingProcessesBypreferredConsumingDay =
            this.groupCookingProcessesBypreferredConsumingDay.bind(this);
        this.toggleCalendarVisible = this.toggleCalendarVisible.bind(this);
        this.cellTemplate = this.cellTemplate.bind(this);
        this.onCalendarClick = this.onCalendarClick.bind(this);
        this.findClosestPrevDate = this.findClosestPrevDate.bind(this);
        this.goToNextDate = this.goToNextDate.bind(this);
    }

    async componentDidMount() {
        this.toggleLoadPanel(true);
        localStorage.setItem("customersUid", this.state.customersUid);

        await Promise.all([
            store
                .dispatch(getCustomerByCustomerUid(this.state.customersUid))
                .then((response) => {
                    this.setState({ customer: response });
                })
                .catch((err) => {
                    notify(getToast("Fehler beim Laden der Kundendaten", "error"));
                }),
            store
                .dispatch(getAllDaysToCookCustomerUid(this.state.customersUid))
                .then((response) => {
                    this.setState({ requestedDeliveryTimes: Array.isArray(response) ? response : [] });
                })
                .catch((err) => {
                    notify(getToast("Fehler beim Laden der Kundendaten", "error"));
                }),
            store
                .dispatch(
                    fetchAllCookingProcessesByCustomerUIDAndDay(
                        this.state.customersUid,
                        this.props.cookingProcess.cookingProcessesCalendarDate
                    )
                )
                .then((response) => {
                    this.setState({ cookingProcesses: Array.isArray(response) ? response : [] });
                })
                .catch((err) => {
                    notify(getToast("Fehler beim Laden der Kochprozesse", "error"));
                }),
        ]);
        this.toggleLoadPanel(false);
        if (this.state.cookingProcesses.length === 0) {
            store
                .dispatch(getAllDaysToCookCustomerUid(this.state.customersUid))
                .then((response) => {
                    var now = new Date(Date.now());
                    var nextDate = this.findClosestPrevDate(response, now);
                    if (nextDate != null) this.setState({ nextCookingProcessDate: nextDate });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let parentId = null;
        let showChildCookingProcesses = true;
        localStorage.setItem("customersUid", this.state.customersUid);
        if (this.props.match.params.parentId) {
            parentId = parseInt(this.props.match.params.parentId);
        }
        if (this.props.match.params.type) {
            showChildCookingProcesses = this.props.match.params.type === "p";
        }

        if (this.state.parentId !== parentId) {
            this.setState({
                parentId: parentId,
            });
        }
        if (this.state.showChildCookingProcesses !== showChildCookingProcesses) {
            this.setState({
                showChildCookingProcesses: showChildCookingProcesses,
            });
        }
        if (
            this.props.cookingProcess.cookingProcessesCalendarDate !==
            prevProps.cookingProcess.cookingProcessesCalendarDate
        ) {
            this.toggleLoadPanel(true);
            store
                .dispatch(
                    fetchAllCookingProcessesByCustomerUIDAndDay(
                        this.state.customersUid,
                        this.props.cookingProcess.cookingProcessesCalendarDate
                    )
                )
                .then((response) => {
                    this.toggleLoadPanel(false);
                    this.toggleCalendarVisible();
                    this.setState({ cookingProcesses: response });
                })
                .catch((err) => {
                    this.toggleLoadPanel(false);
                    notify(getToast("Fehler beim Laden der Kochprozesse", "error"));
                });
        }
    }

    toggleCalendarVisible() {
        store.dispatch(showCookingProcessesCalendar(false));
    }

    finishCookingProcess(cookingProcessId, date) {
        this.toggleLoadPanel(true);
        store
            .dispatch(finishCookingProcessCustomer(cookingProcessId, this.state.customersUid))
            .then(async (response) => {
                notify(getToast("Kochprozess erfolgreich abgeschlossen", "success"));

                const cookingProcesses = await store.dispatch(
                    fetchAllCookingProcessesByCustomerUIDAndDay(
                        this.state.customersUid,
                        this.props.cookingProcess.cookingProcessesCalendarDate
                    )
                );

                const requestedDeliveryTimes = await store.dispatch(
                    getAllDaysToCookCustomerUid(this.state.customersUid)
                );

                const now = new Date(Date.now());
                const nextDate = this.findClosestPrevDate(requestedDeliveryTimes, now);
                const noMoreProcesses = cookingProcesses.length === 0 && !nextDate;

                this.setState(
                    {
                        cookingProcesses,
                        requestedDeliveryTimes,
                        isLoading: false,
                    },
                    () => {
                        if (noMoreProcesses) {
                            store.dispatch(setCookingProcessesCalendarDate(now));
                            this.setState({ nextCookingProcessDate: null });
                        } else {
                            this.setState({ nextCookingProcessDate: nextDate });
                        }
                        history.push("/guest/allCookingProcessesCustomer/" + this.state.customersUid + "/p");
                    }
                );
            })
            .catch((err) => {
                this.toggleLoadPanel(false);
                notify(getToast("Fehler beim Abschließen des Kochprozesses", "error"));
            });
    }

    toggleLoadPanel(isLoading) {
        this.setState({
            isLoading: isLoading,
        });
    }

    groupCookingProcessesBypreferredConsumingDay(cookingProcesses) {
        var parentId = this.state.parentId;
        var reducedList = cookingProcesses.reduce(function (rv, x) {
            if (x.parentId === parentId) {
                (rv[x.preferredConsumingDay] = rv[x.preferredConsumingDay] || []).push(x);
            }
            return rv;
        }, {});
        var groupedCookingProcesses = [];
        for (const [key, value] of Object.entries(reducedList)) {
            groupedCookingProcesses.push({ key: key, value: value });
        }
        groupedCookingProcesses.sort(function (a, b) {
            return new Date(a.key) - new Date(b.key);
        });
        return groupedCookingProcesses;
    }

    onCalendarClick(e) {
        store.dispatch(setCookingProcessesCalendarDate(e.value));
    }

    cellTemplate(cell, requestedDeliveryTimes) {
        return <span className={this.getCellCssClass(cell.date, requestedDeliveryTimes)}>{cell.text}</span>;
    }

    getCellCssClass(date, requestedDeliveryTimes) {
        if (
            Array.isArray(requestedDeliveryTimes) &&
            requestedDeliveryTimes.some((e) => this.datesAreOnSameDay(new Date(e), new Date(date)))
        ) {
            return "requestedDeliveryDay";
        }
        return "";
    }

    cellDisabled(date, requestedDeliveryTimes) {
        if (date.view === "month")
            return !requestedDeliveryTimes.some((e) => this.datesAreOnSameDay(new Date(e), new Date(date.date)));
        else return false;
    }

    findClosestPrevDate(arr, target) {
        let targetDate = new Date(target);
        let previousDates = arr.filter((e) => targetDate - new Date(e) < 0);
        let sortedPreviousDates = previousDates.filter((a, b) => new Date(a) - new Date(b));
        return sortedPreviousDates[0] || null;
    }

    datesAreOnSameDay(first, second) {
        return (
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        );
    }

    goToNextDate() {
        if (this.state.nextCookingProcessDate) {
            store.dispatch(setCookingProcessesCalendarDate(this.state.nextCookingProcessDate));
        }
    }

    render() {
        const { cookingProcess } = this.props;
        moment.locale(cookingProcess.customerLanguage);
        locale(cookingProcess.customerLanguage);
        const noFutureProcesses = !this.state.nextCookingProcessDate;

        return (
            <div id="cookingProcessCustomer">
                {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess && (
                    <div className="parentCookingProcess">
                        <h1 className="title">
                            {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight === 0
                                ? ""
                                : formatGrammage(
                                      this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight
                                  ) +
                                  " " +
                                  getGrammageUnit(
                                      this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight
                                  )}
                            {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.Article.name}
                        </h1>
                    </div>
                )}
                {this.state.showChildCookingProcesses && (
                    <div className="cookingProcessCustomerHeader">
                        <p className="cookingProcessCustomerWelcomeText">
                            <span className="title">
                                Hallo
                                {this.state.customer && this.state.customer.firstName && this.state.customer.lastName
                                    ? ` ${this.state.customer.firstName} ${this.state.customer.lastName}`
                                    : ""}
                                ,
                            </span>
                            <span>viel Spaß beim Kochen!</span>
                        </p>
                    </div>
                )}
                <div id="allCookingProcessesOverview" className="guest">
                    <LoadPanel visible={this.state.isLoading} />
                    <div className="tilesContainerWrapper">
                        <div id="tilesContainer">
                            {this.state.cookingProcesses && (
                                <React.Fragment>
                                    <div className="groupedCookingProcessesCustomer">
                                        <Swiper
                                            spaceBetween={20}
                                            autoHeight={true}
                                            centeredSlides={false}
                                            modules={[Navigation, Pagination]}
                                            pagination={{ el: ".my-custom-pagination-div", clickable: true }}
                                            navigation={true}
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 2,
                                                    autoHeight: false,
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    autoHeight: false,
                                                },
                                                1366: {
                                                    slidesPerView: 4,
                                                    autoHeight: false,
                                                },
                                                1680: {
                                                    slidesPerView: 5,
                                                    autoHeight: false,
                                                },
                                                1920: {
                                                    slidesPerView: 6,
                                                    autoHeight: false,
                                                },
                                            }}
                                        >
                                            {!this.state.isLoading &&
                                                (!this.state.cookingProcesses ||
                                                    this.state.cookingProcesses.length === 0) && (
                                                    <Card
                                                        variant="outlined"
                                                        onClick={noFutureProcesses ? null : this.goToNextDate}
                                                    >
                                                        <CardContent>
                                                            <Typography variant="subtitle1" gutterBottom>
                                                                Für heute sind keine Kochprozesse verfügbar.
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions sx={{ justifyContent: "flex-end" }}>
                                                            {!noFutureProcesses ? (
                                                                <Button onClick={this.goToNextDate}>
                                                                    Nächster Kochprozess{" "}
                                                                    {moment(
                                                                        new Date(this.state.nextCookingProcessDate)
                                                                    ).format("dd DD. MMM")}{" "}
                                                                    <ArrowForwardIcon />
                                                                </Button>
                                                            ) : (
                                                                <Typography variant="subtitle1">
                                                                    Keine weiteren Kochprozesse geplant
                                                                </Typography>
                                                            )}
                                                        </CardActions>
                                                    </Card>
                                                )}
                                            {this.state.cookingProcesses &&
                                                this.state.showChildCookingProcesses &&
                                                this.state.cookingProcesses.map((cookingProcess, index) => {
                                                    if (
                                                        cookingProcess.parentId === this.state.parentId ||
                                                        !cookingProcess.parentId
                                                    ) {
                                                        return (
                                                            <SwiperSlide key={cookingProcess.cookingProcessId}>
                                                                <AllCookingProcessesOverviewForm
                                                                    key={cookingProcess.cookingProcessId}
                                                                    data={cookingProcess}
                                                                    finishCookingProcess={this.finishCookingProcess}
                                                                    isCookingProcessCustomer={true}
                                                                    customersUid={this.state.customersUid}
                                                                />
                                                            </SwiperSlide>
                                                        );
                                                    } else
                                                        return <React.Fragment key={cookingProcess.cookingProcessId} />;
                                                })}
                                            {this.state.cookingProcesses &&
                                                !this.state.showChildCookingProcesses &&
                                                this.state.cookingProcesses.map((cookingProcess, index) => {
                                                    if (
                                                        cookingProcess.cookingProcessId === this.state.parentId ||
                                                        !cookingProcess.parentId
                                                    ) {
                                                        return (
                                                            <SwiperSlide key={cookingProcess.cookingProcessId}>
                                                                <AllCookingProcessesOverviewForm
                                                                    key={cookingProcess.cookingProcessId}
                                                                    data={cookingProcess}
                                                                    finishCookingProcess={this.finishCookingProcess}
                                                                    isCookingProcessCustomer={true}
                                                                    customersUid={this.state.customersUid}
                                                                />
                                                            </SwiperSlide>
                                                        );
                                                    } else
                                                        return <React.Fragment key={cookingProcess.cookingProcessId} />;
                                                })}
                                        </Swiper>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                className="my-custom-pagination-div"
                                                style={{ position: "absolute", bottom: "30px" }}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <Popup
                    visible={cookingProcess.cookingProcessesCalendarVisible}
                    onHiding={this.toggleCalendarVisible}
                    hideOnOutsideClick={true}
                    width="auto"
                    height="auto"
                    maxWidth={330}
                    minWidth={200}
                    showCloseButton={true}
                    title="Tag auswählen"
                    className="cookingProcessesCalendarPopup"
                >
                    <div id="iconPopups">
                        <Calendar
                            id="calendar-container"
                            firstDayOfWeek={1}
                            customerLanguage={this.props.cookingProcess.customerLanguage}
                            onValueChanged={this.onCalendarClick}
                            value={this.props.cookingProcess.cookingProcessesCalendarDate}
                            disabledDates={(cell) => this.cellDisabled(cell, this.state.requestedDeliveryTimes)}
                            cellRender={(cell) => this.cellTemplate(cell, this.state.requestedDeliveryTimes)}
                        />
                    </div>
                </Popup>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { cookingProcess } = state;
    return {
        cookingProcess,
    };
}

export default compose(withReduxConnect(mapStateToProps), withRouter)(AllCookingProcessesOverviewCustomer);
