import notify from "devextreme/ui/notify";
import Config from "../Config";
import { authHeader, handleResponse, getToast, authHeaderForAzure } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_LICENSE = "REQUEST_LICENSE";
export function requestLicense() {
    return {
        type: REQUEST_LICENSE,
    };
}

export const RECEIVE_LICENSE = "RECEIVE_LICENSE";
export function receiveLicense(json) {
    return {
        type: RECEIVE_LICENSE,
        data: json,
    };
}

export function getDataProtectionPolicy(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    }
    return function (dispatch){
        return fetch(config.backendHost + "/Licenses/getDataProtectionPolicy", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)))
    }
}

export function getLicenseNumber(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    }
    return function (dispatch){
        return fetch(config.backendHost + "/Licenses/getLicenseNumber", requestOptions)
        .then((response) => handleResponse(response));
    }
}

export function updateDataProtectionPolicy(dataProtectionPolicy){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(dataProtectionPolicy),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateDataProtectionPolicy", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)));
    }
}

export function getImprint(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    }
    return function (dispatch){
        return fetch(config.backendHost + "/Licenses/getImprint", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)))
    }
}

export function updateImprint(imprint){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(imprint),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateImprint", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)));
    }
}

export function getTermsAndConditions(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    }
    return function (dispatch){
        return fetch(config.backendHost + "/Licenses/getTermsAndConditions", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)))
    }
}

export function updateTermsAndConditions(termsAndConditions){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(termsAndConditions),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateTermsAndConditions", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)));
    }
}

export function getWithdrawalNotice(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    }
    return function (dispatch){
        return fetch(config.backendHost + "/Licenses/getWithdrawalNotice", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)))
    }
}

export function updateWithdrawalNotice(withdrawalNotice){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(withdrawalNotice),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateWithdrawalNotice", requestOptions)
        .then((response) => handleResponse(response))
        .then((json) => dispatch(receiveLicense(json)));
    }
}

export function getLicense() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        dispatch(requestLicense());
        return fetch(`${config.apiUrl}/${config.coreModule}/getLicense`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveLicense(json)));
    };
}

export function getLicenseModules() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/getModules", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveLicense(json)));
    };
}

export function updateLicense(licenseKey) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ licenseKey: licenseKey }),
    };
    return function (dispatch) {
        dispatch(requestLicense());
        return fetch(config.backendHost + "/Licenses/updateKey", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveLicense(json)));
    };
}

export function updateLicenseLogo(media = null) {
    const guidFileName = media ? media.guidFileName : "";
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(guidFileName),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateBIOSLogo", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateReportLogo(media = null) {
    const guidFileName = media ? media.guidFileName : "";
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(guidFileName),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/updateReportLogo", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getFGLogoUrl() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getFGLogoUrl`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getReportLogoUrl() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/getReportLogoUrl", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function licenseIsValid() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        dispatch(requestLicense());
        return fetch(`${config.apiUrl}/${config.coreModule}/getLicense`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                if (json) {
                    if (!json.isValid) {
                        notify(getToast("Diese Lizenz ist ungültig!", "error"));
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            });
    };
}

export function getShopFrontendUrl() {
    const reuqestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/getShopFrontendUrl", reuqestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
