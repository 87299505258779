import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute = ({ children, hasRight }) => {
    const location = useLocation();
    let u = JSON.parse(localStorage.getItem("user"));

    if (!u || !u.Id || !u.Username || !u.Token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!hasRight) {
        return (
            <div>
                <h2 className="defaultText">Sie haben keine Berechtigung für diesen Bereich!</h2>
            </div>
        );
    }

    return children;
};
