import React, { Component } from "react";
import store from "../../../store";
import { fetchAllCookingProcessesWithStartedTimer } from "../../../actions/cookingProcessActions";
import { Button, Popover, LoadPanel } from "devextreme-react";
import "./currentTimers.scss";
import CountDownTimer from "../countDownTimer/countDownTimer";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "../../../helpers/withRouter";
import { withReduxConnect } from "../../../helpers/withReduxConnect";

class CurrentTimers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreTimersVisible: false,
        };

        this.toggleMoreTimers = this.toggleMoreTimers.bind(this);
        this.goToCookingProcess = this.goToCookingProcess.bind(this);
    }

    toggleMoreTimers() {
        this.setState({
            moreTimersVisible: !this.state.moreTimersVisible,
        });
    }

    goToCookingProcess(cookingProcessId, phasesId, stepsId) {
        if (cookingProcessId && phasesId && stepsId) {
            this.props.history.push("/kochprozess/" + cookingProcessId + "/" + phasesId + "/" + stepsId);
        }
    }

    componentDidMount() {
        store.dispatch(fetchAllCookingProcessesWithStartedTimer());
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.props.cookingProcess.cookingProcesses.length > 0 &&
                    !this.props.cookingProcess.isFetching &&
                    this.props.cookingProcess.cookingProcesses.find((element) => !element.cookingProcessEndtime) !=
                        null && (
                        <div id="CurrentTimers">
                            <LoadPanel visible={this.state.fetchCookingProcess} />
                            {!this.props.cookingProcess.cookingProcesses[0].cookingProcessEndtime &&
                                timer(
                                    this.props.cookingProcess.cookingProcesses[0],
                                    t(this.props.cookingProcess.cookingProcesses[0].Article.name),
                                    t(this.props.cookingProcess.cookingProcesses[0].CurrentPhase.PhaseType.name)
                                )}

                            {this.props.cookingProcess.cookingProcesses.length === 1 &&
                                !this.props.cookingProcess.cookingProcesses[0].cookingProcessEndtime && (
                                    <div className="singleCookingProcessButton">
                                        <Button
                                            type="success"
                                            icon="chevronright"
                                            onClick={() => {
                                                let cookingProcessId =
                                                    this.props.cookingProcess.cookingProcesses[0].cookingProcessId;
                                                let phasesId =
                                                    this.props.cookingProcess.cookingProcesses[0].currentPhasesId;
                                                let stepsId =
                                                    this.props.cookingProcess.cookingProcesses[0].currentStepsId;
                                                this.goToCookingProcess(cookingProcessId, phasesId, stepsId);
                                            }}
                                        />
                                    </div>
                                )}

                            {this.props.cookingProcess.cookingProcesses.length > 1 && (
                                <React.Fragment>
                                    <div className="moreTimers">
                                        <Button icon="plus" type="success" onClick={this.toggleMoreTimers} />
                                        <span className="badge">
                                            {this.props.cookingProcess.cookingProcesses.length - 1}
                                        </span>
                                    </div>
                                    <Popover
                                        target="#CurrentTimers"
                                        position="bottom"
                                        visible={this.state.moreTimersVisible}
                                        onHiding={this.toggleMoreTimers}
                                        className="moreTimersPopover"
                                    >
                                        <div id="cookingTimersList">
                                            {this.props.cookingProcess.cookingProcesses.map((cookingProcess, index) => {
                                                if (!cookingProcess.cookingProcessEndtime) {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                index === 0
                                                                    ? "cookingTimersListItem first"
                                                                    : "cookingTimersListItem"
                                                            }
                                                        >
                                                            {timer(
                                                                cookingProcess,
                                                                t(cookingProcess.Article.name),
                                                                t(cookingProcess.CurrentPhase.PhaseType.name)
                                                            )}
                                                            <Button
                                                                type="success"
                                                                icon="chevronright"
                                                                onClick={() => {
                                                                    let cookingProcessId =
                                                                        cookingProcess.cookingProcessId;
                                                                    let phasesId = cookingProcess.currentPhasesId;
                                                                    let stepsId = cookingProcess.currentStepsId;
                                                                    this.goToCookingProcess(
                                                                        cookingProcessId,
                                                                        phasesId,
                                                                        stepsId
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return <React.Fragment />;
                                                }
                                            })}
                                        </div>
                                    </Popover>
                                </React.Fragment>
                            )}
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

function timer(cookingProcess, articleName, phaseTypeName) {
    if (cookingProcess) {
        return (
            <div className="timer">
                <h2>
                    {articleName} <span>{phaseTypeName}</span>
                </h2>
                <div className="countdown">
                    <CountDownTimer
                        duration={cookingProcess.CurrentStep.stepTimeSec}
                        startTime={cookingProcess.timerStartTimeStamp}
                        soundEnabled={true}
                        type={"progressbar"}
                    />
                </div>
            </div>
        );
    }

    return null;
}

function mapStateToProps(state) {
    const { cookingProcess } = state;
    return { cookingProcess };
}

export default compose(
    withReduxConnect(mapStateToProps),
    withTranslation(["dynamicTranslation"])
)(withRouter(CurrentTimers));
