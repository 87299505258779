import Config from "../../Config";
import { authHeader, authHeaderForAzure, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_PRICEGROUPS = "REQUEST_PRICEGROUPS";
export function requestPriceGroups() {
    return {
        type: REQUEST_PRICEGROUPS,
    };
}

export const RECEIVE_PRICEGROUPS = "RECEIVE_PRICEGROUPS";
export function receivePriceGroups(json) {
    return {
        type: RECEIVE_PRICEGROUPS,
        priceGroups: json,
    };
}

/*Diese Funktion lädt alle Preisgruppen*/
export function fetchPriceGroups(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        dispatch(requestPriceGroups());
        return fetch(`${config.apiUrl}/${config.coreModule}/getPriceGroups`, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t))
            .then((json) => dispatch(receivePriceGroups(json)));
    };
}

export function updatePriceGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/PriceGroups/update", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertPriceGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/PriceGroups/insert", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deletePriceGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/PriceGroups/delete", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
