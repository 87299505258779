import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "../../../../helpers/withRouter";
import { Button, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import { login } from "../../../../actions/identityActions";
import store from "../../../../store";
import "./loginForm.scss";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";
import { getUserLanguage } from "../../../../actions/userActions";
import { withReduxConnect } from "../../../../helpers/withReduxConnect";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.redirectLoggedInUser = this.redirectLoggedInUser.bind(this);

        this.handleEmailChange = (e) => {
            this.setState({
                username: e.value,
            });
        };

        this.handlePasswordChange = (e) => {
            this.setState({
                password: e.value,
            });
        };
    }

    componentDidMount() {
        this.redirectLoggedInUser();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.identity.user.Token && prevProps.identity.user.Token !== this.props.identity.user.Token) {
            this.redirectLoggedInUser(prevProps);
        }
    }

    redirectLoggedInUser(prevProps) {
        const { i18n } = this.props;
        if (this.props.identity.user.Token && prevProps.identity.user.Token !== this.props.identity.user.Token) {
            store
                .dispatch(getUserLanguage())
                // .then(() => i18n.changeLanguage(this.props.identity.user.selectedLanguage))
                .then((response) => {
                    return i18n.changeLanguage(this.props.identity.user.selectedLanguage);
                })
                .then(() => {
                    window.location.href = "/#/";
                    window.location.reload();
                });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { username, password } = this.state;
        if (username && password) {
            const { selectedLanguage } = this.props.settings.loginTranslations;
            store.dispatch(login(username, password, selectedLanguage)).then((response) => {
                if (response.user.status === 401) {
                    notify(getToast(response.user.errorMessage, "error"));
                } else {
                    const userChangedEvent = new CustomEvent("userChanged");
                    window.dispatchEvent(userChangedEvent);
                }
            });
        }
    }

    render() {
        const { loginTranslations } = this.props.settings;
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <ValidationGroup>
                        <div className={"dx-field"}>
                            <TextBox
                                name="username"
                                mode="email"
                                placeholder={loginTranslations ? loginTranslations.email : "E-Mail"}
                                required
                                onValueChanged={this.handleEmailChange}
                            >
                                <Validator>
                                    <RequiredRule
                                        message={
                                            loginTranslations
                                                ? loginTranslations.fieldIsRequired
                                                : "Dieses Feld wird benötigt"
                                        }
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="password"
                                mode="password"
                                placeholder={loginTranslations ? loginTranslations.password : "Passwort"}
                                required
                                onValueChanged={this.handlePasswordChange}
                            >
                                <Validator>
                                    <RequiredRule
                                        message={
                                            loginTranslations
                                                ? loginTranslations.fieldIsRequired
                                                : "Dieses Feld wird benötigt"
                                        }
                                    />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <div className={"dx-field-value"}>
                                <Button
                                    text={loginTranslations ? loginTranslations.login : "Login"}
                                    type="default"
                                    useSubmitBehavior={true}
                                />
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity, settings } = state;
    return {
        identity,
        settings,
    };
}

export default compose(
    withReduxConnect(mapStateToProps),
    withTranslation(["dynamicTranslation"]),
    withRouter
)(LoginForm);
