import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllSettings() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Settings/getAllSettings", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAssemblyVersion() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/getAssemblyVersion", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveVersion(json)));
    };
}

export const RECEIVE_VERSION = "RECEIVE_VERSION";
export function receiveVersion(json) {
    return {
        type: RECEIVE_VERSION,
        version: json,
    };
}

export function getAllSettingsLocalized() {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ language: "german" }),
        };
        return fetch(config.backendHost + "/Settings/getAllSettingsLocalized", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getShopLogoUrl() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/GetShopLogoUrl", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function updateSettings(newValue, data) {
    let body = {};

    body[data.origName] = {
        value: newValue,
        type: data.type,
        comment: data.comment,
        validationRules: data.validationRules,
        showOnSetupPage: data.showOnSetupPage,
    };

    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        };
        return fetch(config.backendHost + "/Settings/updateSettings", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateSettingsGerman(newValue, data) {
    let body = {};

    body[data.origName] = {
        value: newValue,
        type: data.type,
        comment: data.comment,
        validationRules: data.validationRules,
        showOnSetupPage: data.showOnSetupPage,
    };

    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        };
        return fetch(config.backendHost + "/Settings/updateSettingsGerman", requestOptions).then((response) => {
            dispatch(clearCachePool("shop"));
            return handleResponse(response);
        });
    };
}

export function updateBlsSheet(formData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: formData,
        };

        return fetch(config.backendHost + "/Settings/updateBlsSheet", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function clearCachePool(cachePool) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ cachePool: cachePool }),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.shopModule}/clearCache`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
