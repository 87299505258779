import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Ingredients from "./ingredients";
import Tool from "./tool";
import { useTranslation } from "react-i18next";
import { hasRight, skipTimer } from "../../../../helpers/rights";
import { withRouter } from "../../../../helpers/withRouter";

function Instructions(props) {
    const { t } = useTranslation(["dynamicTranslation"]);
    return (
        <div className="box instructions">
            <header>
                <div style={{ display: "flex" }}>
                    {props.phase.Steps.length > 1 && (
                        <div className="allSteps" onClick={props.toggleAllSteps}>
                            <span>{t("CookingProcess.AllSteps")}</span>
                            {props.showAllSteps ? (
                                <KeyboardArrowDownIcon fontSize="large" />
                            ) : (
                                <ChevronRightIcon fontSize="large" />
                            )}
                        </div>
                    )}
                    <div
                        className={props.goToNextStepEnabled || hasRight(skipTimer) ? "finished" : "finishedDisabled"}
                        onClick={() => {
                            if (props.goToNextStepEnabled || hasRight(skipTimer)) {
                                props.goToNext();
                            }
                        }}
                    >
                        <span>{t("CookingProcess.Done")}</span>
                        <ChevronRightIcon fontSize="large" />
                    </div>
                </div>
            </header>
            <div className="box-content">
                <div className="box-content-item">
                    <Ingredients stepIngredients={props.stepIngredients} tempIngredients={props.tempIngredients} />
                </div>
                <div className="box-content-item">
                    <div className="inner-box instruction">
                        <header>{t("CookingProcess.Instructions")}</header>
                        <div className="inner-box-content">
                            <table>
                                <tbody>
                                    {props.steps.map((step, index) => {
                                        if (!props.showAllSteps && step.stepsId !== props.step.stepsId) {
                                            return null;
                                        }
                                        return (
                                            <tr key={step.stepsId}>
                                                <td
                                                    style={{ display: "block", width: 30 }}
                                                    className={
                                                        step.stepsId === props.step.stepsId ? "" : "inner-box-stepsDone"
                                                    }
                                                >
                                                    {index + 1}.
                                                </td>
                                                <td
                                                    className={
                                                        step.stepsId === props.step.stepsId ? "" : "inner-box-stepsDone"
                                                    }
                                                >
                                                    {t(step.text)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {props.step.Tool !== null && (
                    <div className="box-content-item">
                        <Tool stepTools={props.step.StepTools} />
                    </div>
                )}
            </div>

            <footer></footer>
        </div>
    );
}

export default withRouter(Instructions);
