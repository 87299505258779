import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadPanel } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import React, { Component } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./cookingProcess.scss";
import {
    fetchCookingProcessById,
    fetchCookingProcessPhaseById,
    fetchPhaseIngredients,
    fetchCookingProcessCustomerById,
    fetchCookingProcessCustomerPhaseById,
    fetchCustomerPhaseIngredients,
} from "../../../actions/cookingProcessActions";
import store from "../../../store";
import { formatGrammage, getGrammageUnit } from "../../../helpers/units";
import { withTranslation } from "react-i18next";

// Wrapper-Funktion für withRouter
function withRouter(Component) {
    return function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    };
}

class CookingProcessIngredientList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cookingProcess: undefined,
            steps: [],
            isLoading: false,
            phaseIngredients: [],
            servingCount: 0,
        };

        this.goToNext = this.goToNext.bind(this);
        this.toggleLoadPanel = this.toggleLoadPanel.bind(this);
        this.getServingsCount = this.getServingsCount.bind(this);
    }

    async componentDidMount() {
        const { customersUid, id, phasesId, stepsId } = this.props.router.params;
        let cookingProcess = undefined;
        let phase = undefined;
        let phaseIngredients = [];

        if (id) {
            this.toggleLoadPanel();
            if (customersUid) {
                await Promise.all([
                    store
                        .dispatch(fetchCookingProcessCustomerById(customersUid, id))
                        .then((response) => {
                            cookingProcess = response;
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                    store
                        .dispatch(fetchCookingProcessCustomerPhaseById(customersUid, phasesId))
                        .then((response) => {
                            phase = response;
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                    store
                        .dispatch(fetchCustomerPhaseIngredients(customersUid, id, phasesId))
                        .then((response) => {
                            phaseIngredients = response ? response : [];
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                ]);
            } else {
                await Promise.all([
                    store
                        .dispatch(fetchCookingProcessById(id))
                        .then((response) => {
                            cookingProcess = response;
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                    store
                        .dispatch(fetchCookingProcessPhaseById(phasesId))
                        .then((response) => {
                            phase = response;
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                    store
                        .dispatch(fetchPhaseIngredients(id, phasesId))
                        .then((response) => {
                            phaseIngredients = response ? response : [];
                        })
                        .catch((error) => {
                            this.toggleLoadPanel();
                        }),
                ]);
            }
        }
        if (cookingProcess && phaseIngredients.length > 0) {
            this.setState(
                {
                    cookingProcess: cookingProcess,
                    currentPhaseName: phase.PhaseType.name,
                    phaseIngredients: phaseIngredients,
                },
                () => this.toggleLoadPanel()
            );
        } else {
            if (customersUid) {
                this.props.router.navigate(
                    "/guest/cookingProcess/" + id + "/" + phasesId + "/" + stepsId + "/" + customersUid
                );
            } else {
                this.props.router.navigate("/kochprozess/" + id + "/" + phasesId + "/" + stepsId);
            }
        }

        this.getServingsCount();
    }

    toggleLoadPanel() {
        this.setState({
            isLoading: !this.state.isLoading,
        });
    }

    getWeight() {
        if (this.state.cookingProcess.weight === 0) return "";
        else {
            return (
                formatGrammage(this.state.cookingProcess.weight) +
                " " +
                getGrammageUnit(this.state.cookingProcess.weight)
            );
        }
    }

    getServingsCount() {
        if (this.state.cookingProcess === undefined) return 0;
        else {
            this.setState({ servingCount: this.state.cookingProcess.portionSum });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <LoadPanel visible={this.state.isLoading} />
                {this.state.cookingProcess && (
                    <div id="cookingProcess">
                        <header>
                            <div className="title">
                                <h1>
                                    {this.getWeight()} {t(this.state.cookingProcess.Article.name)}
                                </h1>
                                <h2>
                                    {t("CookingProcess.ListOfIngredientsForPhase")} {t(this.state.currentPhaseName)}
                                </h2>
                            </div>
                        </header>
                        <div className="content">
                            <div className="left">
                                {this.state.cookingProcess && (
                                    <div className="box text">
                                        <header>
                                            <div style={{ display: "flex" }}>
                                                <div className="finished" onClick={this.goToNext}>
                                                    <span>{t(this.state.currentPhaseName) + " " + t("starten")} </span>
                                                    <ChevronRightIcon fontSize="large" />
                                                </div>
                                            </div>
                                        </header>
                                        <div className="box ingredients">
                                            <header>
                                                <h3>{t("CookingProcess.Ingredients")}</h3>
                                                {this.state.servingCount > 0 && (
                                                    <h3>
                                                        {t("Für")} {this.state.servingCount}{" "}
                                                        {this.state.servingCount > 1
                                                            ? t("Dishes.Servings")
                                                            : t("Packaging.Serving")}
                                                    </h3>
                                                )}
                                            </header>
                                            <div className="ingredients">
                                                <table>
                                                    <tbody>
                                                        {this.state.phaseIngredients.map((phaseIngredient, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="grammage">
                                                                        {formatGrammage(phaseIngredient.totalGrammage)}
                                                                    </td>
                                                                    <td className="unit">
                                                                        {getGrammageUnit(phaseIngredient.totalGrammage)}
                                                                    </td>
                                                                    <td className="name">
                                                                        {t(phaseIngredient.Ingredient.name)}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <footer></footer>
                                        </div>

                                        <footer></footer>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }

    sortBySequenceNumber(a, b) {
        if (a.sequenceNumber < b.sequenceNumber) {
            return -1;
        }
        if (a.sequenceNumber > b.sequenceNumber) {
            return 1;
        }
        return 0;
    }

    goToNext() {
        const { id, phasesId, stepsId } = this.props.router.params;
        if (this.props.router.params.customersUid) {
            this.props.router.navigate(
                "/guest/cookingProcess/" +
                    id +
                    "/" +
                    phasesId +
                    "/" +
                    stepsId +
                    "/" +
                    this.props.router.params.customersUid
            );
        } else {
            this.props.router.navigate("/kochprozess/" + id + "/" + phasesId + "/" + stepsId);
        }
    }
}

// Exportieren Sie die Komponente mit dem withRouter-Wrapper
export default withTranslation(["dynamicTranslation"])(withRouter(CookingProcessIngredientList));
