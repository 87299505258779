import { Button, LoadPanel, Popup } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import notify from "devextreme/ui/notify";
import React, { Component } from "react";
import {
    cookingProcessUpdateTimer,
    fetchCookingProcessById,
    updateCookingProcess,
    fetchCookingProcessPhaseById,
    fetchPhaseIngredients,
    fetchCookingProcessCustomerById,
    fetchCookingProcessCustomerPhaseById,
    fetchCustomerPhaseIngredients,
    updateCookingProcessCustomer,
    cookingProcessCustomerUpdateTimer,
} from "../../../actions/cookingProcessActions";
import Config from "../../../Config";
import { history } from "../../../helpers/history";
import { getToast } from "../../../helpers/requestHelpers";
import { formatGrammage, getGrammageUnit } from "../../../helpers/units";
import store from "../../../store";
import "./cookingProcess.scss";
import StepPage from "./stepPage/stepPage";
import { withTranslation } from "react-i18next";
import { withRouter } from "../../../helpers/withRouter";

class CookingProcess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cookingProcess: undefined,
            steps: [],
            showAllSteps: false,
            showFinishPhase: false,
            showFinishCookingProcess: false,
            showArticleImage: false,
            isLoading: false,
            phasesId: undefined,
            phase: undefined,
            stepsId: undefined,
            phaseIngredients: [],
            goToNextStepEnabled: true,
            finishPhaseText: "",
        };

        this.getCurrentStepIndex = this.getCurrentStepIndex.bind(this);
        this.toggleAllSteps = this.toggleAllSteps.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.toggleFinishPhase = this.toggleFinishPhase.bind(this);
        this.hideFinishPhase = this.hideFinishPhase.bind(this);
        this.toggleSkipTimer = this.toggleSkipTimer.bind(this);
        this.toggleFinishCookingProcess = this.toggleFinishCookingProcess.bind(this);
        this.finishPhase = this.finishPhase.bind(this);
        this.setCurrentTimerStart = this.setCurrentTimerStart.bind(this);
        this.unsetCurrentTimerStart = this.unsetCurrentTimerStart.bind(this);
        this.finishCookingProcess = this.finishCookingProcess.bind(this);
        this.timerIsRunning = this.timerIsRunning.bind(this);
        this.timerIsExpired = this.timerIsExpired.bind(this);
        this.goToStart = this.goToStart.bind(this);
        this.config = new Config();
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
        const { customersUid } = this.props.match.params;
        this.backListener = this.props.history.listen(() => {
            if (!this.props.location.pathname.includes("/kueche/kochprozesse/")) {
                if (customersUid) {
                    this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
                } else {
                    this.props.history.push("/kueche/kochprozesse/p");
                }
            }
        });
    }

    componentWillUnmount() {
        this.backListener();
    }

    componentDidUpdate() {
        if (this.props) {
            if (
                this.state.stepsId !== this.props.match.params.stepsId ||
                this.state.phasesId !== this.props.match.params.phasesId ||
                this.state.cookingProcess.cookingProcessId !== parseInt(this.props.match.params.id)
            ) {
                // store.dispatch(fetchAllCookingProcessesWithStartedTimer());
                this.getData();
            }
        }
    }

    async getData() {
        const { customersUid, id, phasesId, stepsId } = this.props.match.params;
        const { t } = this.props;
        let cookingProcess = undefined;
        let phase = undefined;
        let phaseIngredients = undefined;
        if (id) {
            if (customersUid) {
                await Promise.all([
                    store.dispatch(fetchCookingProcessCustomerById(customersUid, id)).then((response) => {
                        if (response.errorMessage === "no cookingprocess found") {
                            notify(
                                getToast(
                                    t(
                                        "Kein passender Kochprozess gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
                        } else {
                            cookingProcess = response;
                        }
                    }),
                    store.dispatch(fetchCookingProcessCustomerPhaseById(customersUid, phasesId)).then((response) => {
                        if (response.errorMessage === "no phase found") {
                            notify(
                                getToast(
                                    t(
                                        "Keine passende Phase gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
                        } else {
                            phase = response;
                        }
                    }),
                    store.dispatch(fetchCustomerPhaseIngredients(customersUid, id, phasesId)).then((response) => {
                        if (response.errorMessage === "no cookingprocess found") {
                            notify(
                                getToast(
                                    t(
                                        "Kein passender Kochprozess gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
                        } else if (response.errorMessage === "no phase found") {
                            notify(
                                getToast(
                                    t(
                                        "Keine passende Phase gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
                        } else {
                            phaseIngredients = response;
                        }
                    }),
                ]);
            } else {
                await Promise.all([
                    store.dispatch(fetchCookingProcessById(id)).then((response) => {
                        if (response.errorMessage === "no cookingprocess found") {
                            notify(
                                getToast(
                                    t(
                                        "Kein passender Kochprozess gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/kueche/kochprozesse/p");
                        } else {
                            cookingProcess = response;
                        }
                    }),
                    store.dispatch(fetchCookingProcessPhaseById(phasesId)).then((response) => {
                        if (response.errorMessage === "no phase found") {
                            notify(
                                getToast(
                                    t(
                                        "Keine passende Phase gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/kueche/kochprozesse/p");
                        } else {
                            phase = response;
                        }
                    }),
                    store.dispatch(fetchPhaseIngredients(id, phasesId)).then((response) => {
                        if (response.errorMessage === "no cookingprocess found") {
                            notify(
                                getToast(
                                    t(
                                        "Kein passender Kochprozess gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/kueche/kochprozesse/p");
                        } else if (response.errorMessage === "no phase found") {
                            notify(
                                getToast(
                                    t(
                                        "Keine passende Phase gefunden. Eventuell ist der Prozess bereits abgeschlossen."
                                    ),
                                    "warning"
                                )
                            );
                            this.props.history.push("/kueche/kochprozesse/p");
                        } else {
                            phaseIngredients = response;
                        }
                    }),
                ]);
            }
        }
        if (cookingProcess && cookingProcess.cookingProcessEndtime) {
            if (customersUid) {
                this.props.history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p");
            } else {
                this.props.history.push("/kueche/kochprozesse/p");
            }
        }
        if (cookingProcess && phase) {
            this.setState({
                cookingProcess: cookingProcess,
                steps: phase.Steps.sort(this.sortBySequenceNumber),
                phase: phase,
                phasesId: phasesId,
                stepsId: stepsId,
                phaseIngredients: phaseIngredients,
                currentTimerStart: cookingProcess.timerStartTimeStamp,
            });
        }
    }

    // componentWillUnmount() {
    //     this.backListener();
    // }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.cookingProcess && this.state.phase && (
                    <div id="cookingProcess">
                        <LoadPanel visible={this.state.isLoading} container="#cookingProcess" />
                        <header>
                            <div className="title">
                                <h1>
                                    {this.state.cookingProcess.weight === 0
                                        ? ""
                                        : formatGrammage(this.state.cookingProcess.weight) +
                                          " " +
                                          getGrammageUnit(this.state.cookingProcess.weight)}{" "}
                                    {t(this.state.cookingProcess.Article.name)}
                                </h1>
                                {this.state.phase.Steps.length > 1 && (
                                    <h2>
                                        {t(this.state.phase.PhaseType.name)}{" "}
                                        <span>
                                            (
                                            {t("CookingProcess.Step") +
                                                " " +
                                                this.getCurrentStepIndex() +
                                                " " +
                                                t("CookingProcess.of") +
                                                " "}
                                            {this.state.phase.Steps.length})
                                        </span>
                                    </h2>
                                )}
                            </div>
                        </header>
                        {this.state.stepsId && (
                            <StepPage
                                stepsId={this.state.stepsId}
                                cookingProcessId={this.state.cookingProcess.cookingProcessId}
                                phase={this.state.phase}
                                showAllSteps={this.state.showAllSteps}
                                steps={this.state.steps}
                                customersUid={this.props.match.params.customersUid}
                                currentTimerStart={this.state.currentTimerStart}
                                toggleAllSteps={this.toggleAllSteps}
                                goToNext={this.goToNext}
                                setCurrentTimerStart={this.setCurrentTimerStart}
                                unsetCurrentTimerStart={this.unsetCurrentTimerStart}
                                goToNextStepEnabled={this.state.goToNextStepEnabled}
                                timerIsRunning={this.timerIsRunning}
                                timerIsExpired={this.timerIsExpired}
                                forceStopTimer={this.state.forceStopTimer}
                            />
                        )}
                        <Popup
                            visible={this.state.showFinishPhase}
                            onHiding={this.hideFinishPhase}
                            hideOnOutsideClick={true}
                            width="auto"
                            height="auto"
                            maxWidth={400}
                            minWidth={300}
                            showCloseButton={true}
                            title={this.state.phase.PhaseType.name + " " + t("CookingProcess.Finish")}
                        >
                            <div id="finishPhase">
                                <p>
                                    {this.state.finishPhaseText} {t("Möchtest du die Phase")} "
                                    {t(this.state.phase.PhaseType.name)}" {t("abschließen")}
                                </p>
                                <div className="finishButtons">
                                    <Button
                                        text={t("CookingProcess.No")}
                                        type="normal"
                                        onClick={this.hideFinishPhase}
                                    />
                                    <Button text={t("CookingProcess.Yes")} type="default" onClick={this.finishPhase} />
                                </div>
                            </div>
                        </Popup>
                        <Popup
                            visible={this.state.showSkipTimer}
                            width="auto"
                            height="auto"
                            maxWidth={400}
                            minWidth={300}
                            showCloseButton={true}
                            title={t("Timer überspringen")}
                        >
                            <div id="skipTimer">
                                <p>
                                    {t(
                                        "Möchtest du den Schritt trotz laufendem Timer beenden? Diese Aktion wird dokumentiert."
                                    )}
                                </p>
                                <div className="skipTimerButtons">
                                    <Button
                                        text={t("CookingProcess.No")}
                                        type="normal"
                                        onClick={this.toggleSkipTimer}
                                    />
                                    <Button
                                        text={t("CookingProcess.Yes")}
                                        type="default"
                                        onClick={() => this.goToNext(true)}
                                    />
                                </div>
                            </div>
                        </Popup>

                        <Popup
                            visible={this.state.showFinishCookingProcess}
                            onHiding={this.toggleFinishCookingProcess}
                            hideOnOutsideClick={true}
                            width="auto"
                            height="auto"
                            maxWidth={400}
                            minWidth={300}
                            showCloseButton={true}
                            title={t("Kochprozess abschließen")}
                        >
                            <div>
                                {this.state.cookingProcess.Article.guidFileName !== null && (
                                    <div
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                this.config.imageStorageBlobURL +
                                                this.state.cookingProcess.Article.guidFileName +
                                                ")",
                                            height: "200px",
                                            width: "100%",
                                            marginBottom: "15px",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center center",
                                        }}
                                    ></div>
                                )}
                                {this.state.cookingProcess.Article.guidFileName === null && (
                                    <div className="content">
                                        <i className="dx-icon-photo"></i>
                                    </div>
                                )}
                            </div>
                            <div id="finishPhase">
                                <p>
                                    {t(
                                        "Möchtest du den Kochprozess wirklich beenden? Dieser Kochprozess kann anschließend nicht mehr gestartet werden"
                                    )}
                                </p>
                                <div className="finishButtons">
                                    <Button
                                        text={t("CookingProcess.CheckAgain")}
                                        type="normal"
                                        onClick={this.toggleFinishCookingProcess}
                                    />
                                    <Button
                                        text={t("CookingProcess.Finish")}
                                        type="default"
                                        onClick={this.finishCookingProcess}
                                    />
                                </div>
                            </div>
                        </Popup>
                    </div>
                )}
            </React.Fragment>
        );
    }

    sortBySequenceNumber(a, b) {
        if (a.sequenceNumber < b.sequenceNumber) {
            return -1;
        }
        if (a.sequenceNumber > b.sequenceNumber) {
            return 1;
        }
        return 0;
    }

    getCurrentStepIndex() {
        let result = 0;
        let i = 0;
        let currentStepsId = parseInt(this.props.match.params.stepsId);

        if (this.state.steps.length > 0) {
            while (this.state.steps[i]) {
                if (this.state.steps[i].stepsId === currentStepsId) {
                    result = i + 1;
                }
                i++;
            }
        }

        return result;
    }

    toggleAllSteps() {
        this.setState({
            showAllSteps: !this.state.showAllSteps,
        });
    }

    toggleFinishPhase() {
        this.setState({
            showFinishPhase: !this.state.showFinishPhase,
        });
    }

    hideFinishPhase() {
        this.setState({
            showFinishPhase: false,
        });
    }

    toggleSkipTimer() {
        this.setState({
            showSkipTimer: !this.state.showSkipTimer,
        });
    }

    toggleFinishCookingProcess() {
        this.setState({
            showFinishCookingProcess: !this.state.showFinishCookingProcess,
        });
    }

    getNext(items, currentItem, ident) {
        let nextItem = undefined;
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            if (item[ident] === currentItem[ident]) {
                if (items[index + 1]) {
                    nextItem = items[index + 1];
                    break;
                }
            }
        }
        return nextItem;
    }

    finishPhase() {
        const { t } = this.props;
        const cookingProcess = this.state.cookingProcess;

        this.setState({
            showFinishPhase: false,
            isLoading: true,
        });
        if (this.props.match.params.customersUid) {
            store
                .dispatch(
                    updateCookingProcessCustomer(
                        cookingProcess.cookingProcessId,
                        this.props.match.params.customersUid,
                        true
                    )
                )
                .then((response) => {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.history.push(
                                "/guest/allCookingProcessesCustomer/" + this.props.match.params.customersUid + "/p"
                            );
                        }
                    );
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.FinishingPhaseFailed"), "error"));
                });
        } else {
            store
                .dispatch(updateCookingProcess(cookingProcess.cookingProcessId, true))
                .then((response) => {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.history.push("/kueche/kochprozesse/p");
                        }
                    );
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.FinishingPhaseFailed"), "error"));
                });
        }
    }

    finishCookingProcess() {
        const { t } = this.props;
        const cookingProcess = this.state.cookingProcess;

        this.setState({
            showFinishCookingProcess: false,
            isLoading: true,
        });
        if (this.props.match.params.customersUid) {
            store
                .dispatch(
                    updateCookingProcessCustomer(
                        cookingProcess.cookingProcessId,
                        this.props.match.params.customersUid,
                        true
                    )
                )
                .then((response) => {
                    this.setState({
                        isLoading: false,
                    });
                    this.goToStart();
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.FinishingCookingProcessFailed"), "error"));
                });
        } else {
            store
                .dispatch(updateCookingProcess(cookingProcess.cookingProcessId, true))
                .then((response) => {
                    this.setState({
                        isLoading: false,
                    });
                    this.goToStart();
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.FinishingCookingProcessFailed"), "error"));
                });
        }
    }

    goToStart() {
        this.setState(
            {
                showArticleImage: false,
            },
            () => {
                if (this.props.match.params.customersUid) {
                    this.props.history.push(
                        "/guest/allCookingProcessesCustomer/" + this.props.match.params.customersUid + "/p"
                    );
                } else {
                    this.props.history.push("/kueche/kochprozesse/p");
                }
            }
        );
    }

    goToNext(forceSkipTimer = false) {
        // const currentPhase = this.state.phase;
        if (this.state.currentTimerStart !== null && !forceSkipTimer) {
            this.setState({
                showSkipTimer: true,
            });
            return;
        }
        if (forceSkipTimer) {
            this.setState(
                {
                    showSkipTimer: false,
                    isLoading: true,
                },
                () => {
                    this.unsetCurrentTimerStart(forceSkipTimer);
                }
            );
        } else {
            this.goToNextStep();
        }
    }

    goToNextStep() {
        const { t } = this.props;
        const cookingProcess = this.state.cookingProcess;
        this.setState(
            {
                isLoading: true,
            },
            () => {
                if (this.props.match.params.customersUid) {
                    store
                        .dispatch(
                            updateCookingProcessCustomer(
                                cookingProcess.cookingProcessId,
                                this.props.match.params.customersUid
                            )
                        )
                        .then((response) => {
                            let { phasesId, stepsId } = response;
                            if (!phasesId && !stepsId) {
                                this.setState({
                                    showFinishCookingProcess: true,
                                    isLoading: false,
                                });
                            } else {
                                if (phasesId === 0) {
                                    this.setState({
                                        showFinishPhase: true,
                                        isLoading: false,
                                        finishPhaseText: "",
                                    });
                                } else if (phasesId === -1) {
                                    this.setState({
                                        showFinishPhase: true,
                                        isLoading: false,
                                        finishPhaseText: t(
                                            "CookingProcess.CookingProcessIsFinishedHereAllFurtherPhasesAreCookedByTheCustomer"
                                        ),
                                    });
                                } else {
                                    this.setState(
                                        {
                                            isLoading: false,
                                        },
                                        () => {
                                            this.props.history.push(
                                                "/guest/cookingProcess/" +
                                                    cookingProcess.cookingProcessId +
                                                    "/" +
                                                    phasesId +
                                                    "/" +
                                                    stepsId +
                                                    "/" +
                                                    this.props.match.params.customersUid
                                            );
                                        }
                                    );
                                }
                            }
                        });
                } else {
                    store.dispatch(updateCookingProcess(cookingProcess.cookingProcessId)).then((response) => {
                        let { phasesId, stepsId } = response;
                        if (!phasesId && !stepsId) {
                            this.setState({
                                showFinishCookingProcess: true,
                                isLoading: false,
                            });
                        } else {
                            if (phasesId === 0) {
                                this.setState({
                                    showFinishPhase: true,
                                    isLoading: false,
                                    finishPhaseText: "",
                                });
                            } else if (phasesId === -1) {
                                this.setState({
                                    showFinishPhase: true,
                                    isLoading: false,
                                    finishPhaseText: t(
                                        "CookingProcess.CookingProcessIsFinishedHereAllFurtherPhasesAreCookedByTheCustomer"
                                    ),
                                });
                            } else {
                                this.setState(
                                    {
                                        isLoading: false,
                                    },
                                    () => {
                                        this.props.history.push(
                                            "/kochprozess/" +
                                                cookingProcess.cookingProcessId +
                                                "/" +
                                                phasesId +
                                                "/" +
                                                stepsId
                                        );
                                    }
                                );
                            }
                        }
                    });
                }
            }
        );
    }

    setCurrentTimerStart(date) {
        const cookingProcess = this.state.cookingProcess;
        this.setState({
            goToNextStepEnabled: false,
            currentTimerStart: date,
        });
        if (this.props.match.params.customersUid) {
            store.dispatch(
                cookingProcessCustomerUpdateTimer(
                    cookingProcess.cookingProcessId,
                    date,
                    this.props.match.params.customersUid
                )
            );
        } else {
            store.dispatch(cookingProcessUpdateTimer(cookingProcess.cookingProcessId, date));
        }
    }

    unsetCurrentTimerStart(logSkipTimer = false) {
        const cookingProcess = this.state.cookingProcess;
        this.setState({
            goToNextStepEnabled: true,
            currentTimerStart: null,
        });
        if (this.props.match.params.customersUid) {
            store.dispatch(
                cookingProcessCustomerUpdateTimer(
                    cookingProcess.cookingProcessId,
                    null,
                    this.props.match.params.customersUid
                )
            );
        } else {
            this.setState(
                {
                    forceStopTimer: logSkipTimer,
                },
                () => {
                    store
                        .dispatch(cookingProcessUpdateTimer(cookingProcess.cookingProcessId, null, logSkipTimer))
                        .then(() => {
                            this.goToNextStep();
                        });
                }
            );
        }
    }

    timerIsRunning() {
        // this.setState({
        //     goToNextStepEnabled: false,
        // });
    }

    timerIsExpired() {
        this.setState({
            currentTimerStart: null,
        });
    }
}

export default withTranslation(["dynamicTranslation"])(withRouter(CookingProcess));
